.cm7cm00 {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #000;
  padding: 39px 51px;
  box-sizing: border-box;
  transition: background-color 0.2s ease-out 0s, color 0.2s ease-out 0s;
  z-index: 99;
}
.cm7cm01 {
  background-color: #FAFBFD;
  z-index: 99;
}
.cm7cm02 {
  background-color: white;
}
.cm7cm03 {
  background-color: #fff;
  color: #fff;
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 35px;
  font-size: 40px;
}
.cm7cm04 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
}
.cm7cm05 {
  justify-content: space-between;
}
.cm7cm06 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.cm7cm07 {
  display: flex;
  flex-direction: column;
}
.cm7cm09 {
  color: #000;
}
.cm7cm0a {
  color: #000;
}
.cm7cm0b {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cm7cm0b:hover {
  cursor: pointer;
}
.cm7cm0c {
  display: none;
  align-items: center;
  gap: 10px;
}
.cm7cm0c:hover {
  cursor: pointer;
}
.cm7cm0d {
  position: relative;
  padding: 6px 8px;
  margin: 0 8px;
  text-decoration: none;
}
.cm7cm0d:hover {
  cursor: pointer;
}
.cm7cm0e {
  width: 180px;
  height: 36px;
}
.cm7cm0f {
  font-weight: 700;
}
.cm7cm0g {
  display: inline-flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  border: none;
  background: #1A77F2;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
.cm7cm0g:hover {
  background: #0762DA;
}
.cm7cm0g:active {
  background: #065BCB;
}
.cm7cm0h {
  display: none;
  padding: 8px 24px;
  width: 100%;
  justify-content: center;
}
.cm7cm0j {
  display: none;
  border: none;
  background-color: transparent;
}
.cm7cm0j:hover {
  cursor: pointer;
}
.cm7cm0k {
  width: 100%;
  display: none;
  (max-width: 1023px) {
    display: flex;
  }
}
@media (max-width: 1023px) {
  .cm7cm00 {
    padding: 19px 30px;
  }
  .cm7cm04 {
    display: none;
  }
  .cm7cm06 {
    position: fixed;
    top: auto;
    bottom: 50%;
    transform: translateY(40%);
  }
  .cm7cm0c {
    display: flex;
  }
  .cm7cm0e {
    width: 180px;
    height: 36px;
  }
  .cm7cm0g {
    padding: 8px 24px;
  }
  .cm7cm0j {
    display: block;
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .cm7cm00 {
    padding: 19px 15px;
  }
  .cm7cm0e {
    width: 112px;
    height: 32px;
  }
}
@media (max-width: 359px) {
  .cm7cm05 {
    width: 100%;
  }
  .cm7cm08 {
    display: none;
  }
  .cm7cm0i {
    display: none;
  }
}
._14luahr0 {
  flex-wrap: wrap;
}
._14luahr2 {
  display: flex;
}
._14luahr3 {
  display: inline-flex;
}
._14luahr4 {
  flex-direction: row;
}
._14luahr5 {
  flex-direction: column;
}
._14luahr6 {
  flex-direction: row-reverse;
}
._14luahr7 {
  flex-direction: column-reverse;
}
._14luahr8 {
  align-items: center;
}
._14luahr9 {
  align-items: flex-start;
}
._14luahra {
  align-items: flex-end;
}
._14luahrb {
  align-items: stretch;
}
._14luahrc {
  align-items: baseline;
}
._14luahrd {
  justify-items: center;
}
._14luahre {
  justify-items: flex-start;
}
._14luahrf {
  justify-items: flex-end;
}
._14luahrg {
  justify-items: stretch;
}
._14luahrh {
  justify-items: baseline;
}
._14luahri {
  justify-content: center;
}
._14luahrj {
  justify-content: flex-start;
}
._14luahrk {
  justify-content: flex-end;
}
._14luahrl {
  justify-content: space-between;
}
._14luahrm {
  justify-content: space-around;
}
._14luahrn {
  justify-content: space-evenly;
}
._14luahro {
  padding: 0.5rem 1rem;
}
._14luahrp {
  padding: 0.5rem 0.5rem;
}
._14luahrq {
  padding: 1.5rem;
}
._14luahrr {
  padding: 0;
}
._14luahrs {
  padding-inline: 51px;
}
._14luahrt {
  padding-top: 45px;
}
._14luahru {
  gap: 24px;
}
._14luahrv {
  gap: 32px;
}
._14luahrw {
  gap: 45px;
}
._14luahrx {
  gap: 50px;
}
._14luahry {
  gap: 80px;
}
._14luahrz {
  gap: 0;
}
._14luahr10 {
  gap: 0.625rem;
}
._14luahr11 {
  gap: 1rem;
}
._14luahr12 {
  gap: 2rem;
}
._14luahr14 {
  flex-wrap: wrap;
}
._14luahr15 {
  flex-wrap: nowrap;
}
._14luahr16 {
  width: 100%;
}
._14luahr17 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1264px;
}
._14luahr0 > * {
  flex-grow: 1;
}
@media (max-width: 767px) {
  ._14luahrs {
    padding-inline: 15px;
  }
}
.hrt07j0 {
  margin: 0px;
  transition: opacity 0.2s ease-out,visibility 0.2s ease-out;
  color: inherit;
}
.hrt07j1 {
  color: #fff;
}
.hrt07j2 {
  color: #000;
}
.hrt07j3 {
  color: #444;
}
.hrt07j4 {
  color: #1A77F2;
}
.hrt07j5 {
  color: #ed0024;
}
.hrt07j6 {
  color: #0A954F;
}
.hrt07j7 {
  font-size: 64px;
  line-height: 105%;
  font-weight: 700;
}
.hrt07j8 {
  font-size: 60px;
  line-height: 105%;
  font-weight: 700;
}
.hrt07j9 {
  color: #000;
  font-size: 88px;
  font-weight: 700;
  line-height: 100%;
}
.hrt07ja {
  font-size: 60px;
  line-height: 105%;
  font-weight: 700;
}
.hrt07jb {
  font-size: clamp(1.75rem, 4vw, 2rem);
  line-height: 46px;
}
.hrt07jc {
  font-size: 20px;
  line-height: 28px;
}
.hrt07jd {
  font-size: 16px;
  line-height: 140%;
}
.hrt07je {
  font-size: 20px;
  font-weight: 400;
  line-height: 100%;
}
.hrt07jf {
  color:  #FFF;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
}
.hrt07jg {
  color: #000;
  font-size: 40px;
  font-weight: 400;
  line-height: 140%;
  text-align: start;
  height: 168px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.hrt07jh {
  font-size: 26px;
  line-height: 140%;
}
.hrt07ji {
  color: #444;
  font-size: 26px;
  line-height: 140%;
  text-align: center;
}
.hrt07jj {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}
.hrt07jk {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}
.hrt07jl {
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}
.hrt07jm {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}
@media (max-width: 1023px) {
  .hrt07j7 {
    font-size: 60px;
  }
  .hrt07j8 {
    text-align: center;
  }
  .hrt07ja {
    text-align: center;
  }
  .hrt07je {
    font-size: 40px;
    font-weight: 700;
    line-height: 115%;
  }
  .hrt07jf {
    font-size: 16px;
  }
  .hrt07jg {
    font-size: 36px;
    -webkit-line-clamp: 3;
  }
  .hrt07jh {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .hrt07j7 {
    font-size: 32px;
  }
  .hrt07j8 {
    font-size: 32px;
    text-align: center;
  }
  .hrt07j9 {
    font-size: 45px;
  }
  .hrt07ja {
    font-size: 32px;
    text-align: center;
  }
  .hrt07jg {
    height: 158px;
    font-size: 28px;
    text-align: center;
    -webkit-line-clamp: 4;
  }
  .hrt07jh {
    font-size: 18px;
  }
  .hrt07ji {
    font-size: 18px;
  }
}
@media (max-width: 1439px) {
  .hrt07jc {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 399px) {
  .hrt07jg {
    height: 178px;
    font-size: 26px;
    -webkit-line-clamp: 5;
  }
}
.wwuzun0 {
  display: none;
  width: 100%;
  height: 100%;
}
.wwuzun1 {
  display: none;
  width: 100%;
  height: 100%;
}
.wwuzun2 {
  display: flex;
  flex-direction: column;
}
.wwuzun4 {
  display: none;
  position: relative;
  padding: 6px 8px;
  margin: 0 8px;
  text-decoration: none;
}
.wwuzun4:hover {
  cursor: pointer;
}
.wwuzun5 {
  display: flex;
  flex-direction: column;
}
.wwuzun7 {
  width: 100%;
}
.wwuzun8 {
  display: none;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  border: none;
  background: #1A77F2;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
.wwuzun8:hover {
  background: #0762DA;
}
.wwuzun8:active {
  background: #065BCB;
}
.wwuzun9 {
  display: inline-flex;
  padding: 8px 24px;
  width: 100%;
  justify-content: center;
}
@media (max-width: 1023px) {
  .wwuzun0 {
    display: flex;
    max-width: 100%;
    flex-direction: column;
  }
  .wwuzun8 {
    padding: 8px 24px;
  }
}
@media (max-width: 359px) {
  .wwuzun3 {
    display: none;
  }
  .wwuzun6 {
    display: none;
  }
  .wwuzuna {
    display: none;
  }
}
._1s9ra2l0 {
  --_1s9ra2l1: #282831;
  --_1s9ra2l2: #2d2d38;
  --_1s9ra2l3: #000;
  --_1s9ra2l4: #444;
  --_1s9ra2l5: #1A77F2;
  --_1s9ra2l6: #0762DA;
  --_1s9ra2l7: #065BCB;
  --_1s9ra2l8: #858690;
  --_1s9ra2l9: #EBEBFE;
  --_1s9ra2la: #4B4B52;
  --_1s9ra2lb: #f9617b;
  --_1s9ra2lc: #FB8195;
  --_1s9ra2ld: #c0c0c5;
  --_1s9ra2le: #000000;
  --_1s9ra2lf: #f4f8fb;
  --_1s9ra2lg: #202029;
  --_1s9ra2lh: #393a47;
  --_1s9ra2li: #343541;
  --_1s9ra2lj: #738b94;
  --_1s9ra2lk: #212129;
  --_1s9ra2ll: #ffffff;
  --_1s9ra2lm: #fefefe;
  --_1s9ra2ln: #FAFAFF;
  --_1s9ra2lo: #f9617b;
  --_1s9ra2lp: #FB8195;
  --_1s9ra2lq: #ff5b77;
  --_1s9ra2lr: #dcdcdc;
  --_1s9ra2ls: #e5e5ef;
  --_1s9ra2lt: #f5f5f5;
  --_1s9ra2lu: #a5a5b2;
  --_1s9ra2lv: #333340;
  --_1s9ra2lw: #f5f5fa;
  --_1s9ra2lx: #c6c6d1;
  --_1s9ra2ly: #5d5e6c;
  --_1s9ra2lz: #e65a71;
  --_1s9ra2l10: #d4b335;
  --_1s9ra2l11: #73a55d;
  --_1s9ra2l12: 0px;
  --_1s9ra2l13: 8px;
  --_1s9ra2l14: 12px;
  --_1s9ra2l15: 16px;
  --_1s9ra2l16: 20px;
  --_1s9ra2l17: 24px;
  --_1s9ra2l18: 28px;
  --_1s9ra2l19: 40px;
  --_1s9ra2l1a: 52px;
  --_1s9ra2l1b: 100px;
  --_1s9ra2l1c: 100;
  --_1s9ra2l1d: 200;
  --_1s9ra2l1e: 88px;
  --_1s9ra2l1f: 1264px;
}
* {
  box-sizing: border-box;
  padding: 0;
  color: inherit;
  font-family: inherit;
}
body {
  max-width: 100vw;
  scroll-behavior: smooth;
  margin: 0;
  background-color: #FAFBFD;
  color: var(--_1s9ra2l4);
}
html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}
a {
  background-color: transparent;
  color: inherit;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-decoration: none;
}
svg {
  display: inline-block;
}
ul {
  list-style-position: inside;
}
p {
  line-height: 1.5rem;
}
p a {
  color: var(--_1s9ra2lb);
}
b {
  font-weight: bolder;
}
._12d14do0 {
  box-sizing: border-box;
  width: 100%;
  height: 189px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-top: 1px solid #E9EAEC;
  background: #FAFBFD;
  padding-inline: 54px;
}
._12d14do1 {
  width: 100%;
  max-width: 1319px;
}
._12d14do2 {
  width: 100%;
}
._12d14do3 {
  display: flex;
}
._12d14do4 {
  display: none;
}
._12d14do5 {
  display: inline-flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  border: none;
  background: #1A77F2;
  cursor: pointer;
}
._12d14do5:hover {
  background: #0762DA;
}
._12d14do5:active {
  background: #065BCB;
}
._12d14do6 {
  font-weight: 700;
}
._12d14do7 {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 100%;
}
._12d14do8 {
  color: #878787;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}
._12d14do9 {
  width: 180px;
  height: 36px;
}
@media (max-width: 1023px) {
  ._12d14do0 {
    max-width: 100%;
    padding: 30px;
    height: auto;
  }
  ._12d14do1 {
    gap: 60px;
  }
  ._12d14do3 {
    display: none;
  }
  ._12d14do4 {
    display: flex;
  }
  ._12d14do9 {
    width: 112px;
    height: 36px;
  }
}
@media (max-width: 767px) {
  ._12d14do0 {
    padding: 30px 15px;
  }
  ._12d14do4 {
    flex-direction: column;
    gap: 40px;
  }
}
@media (max-width: 839px) {
  ._12d14do9 {
    width: 112px;
    height: 32px;
  }
}
/* latin-ext */
@font-face {
  font-family: '__Figtree_d680cf';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/5f8bef30161d4c4f-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_d680cf';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/192e369fd43210ef-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Figtree_Fallback_d680cf';src: local("Arial");ascent-override: 94.32%;descent-override: 24.82%;line-gap-override: 0.00%;size-adjust: 100.72%
}.__className_d680cf {font-family: '__Figtree_d680cf', '__Figtree_Fallback_d680cf';font-style: normal
}

